<template>
  <div>
    <!-- <h2 class="text-base text-textgrey text-center mb-6 relative z-10">
      The money app for first-time home buyers
      <strong class="block">Presents:</strong>
    </h2> -->

    <img
      src="@/assets/bits/1.png"
      class="absolute max-w-[260px] top-0 right-0"
    />
    <img
      src="@/assets/bits/2.png"
      class="absolute max-w-[100px] top-[20vh] left-0"
    />
    <!-- 
    <img
      src="@/assets/bits/2.png"
      class="absolute lg:block hidden bit-desktop-1"
    />
    <img
      src="@/assets/bits/1.png"
      class="absolute lg:block hidden bit-desktop-3"
    />
    <img
      src="@/assets/bits/5.png"
      class="absolute lg:block hidden bit-desktop-5"
    /> -->
    <!-- <img src="@/assets/bits/1.png" class="absolute lg:block hidden bit-2" /> -->

    <!-- 
    <div
      class="w-full flex relative items-end justify-centerbg-opacity-30 my-6 cursor-pointer"
      v-on:mousedown="start"
      v-on:touchstart="start"
      v-on:touchend="stop"
      v-on:mouseup="stop"
    >
      <svg
        class="w-full pointer-events-none select-none"
        width="375"
        height="276"
        viewBox="0 0 375 276"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="61.5"
          y="0.5"
          width="252"
          height="275"
          rx="9.5"
          fill="#DAEEEF"
          stroke="#419498"
        />
        <line
          y1="-0.5"
          x2="368.59"
          y2="-0.5"
          transform="matrix(0.675511 0.73735 -0.707387 0.706826 63.3354 2)"
          stroke="#419498"
        />
        <line
          y1="-0.5"
          x2="367.909"
          y2="-0.5"
          transform="matrix(-0.678983 0.734154 -0.704032 -0.710169 312.443 3)"
          stroke="#419498"
        />
        <circle cx="187.5" cy="137.5" r="27.5" fill="#DAEEEF" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M178.203 127H195.827C197.044 127 198.031 127.986 198.031 129.203V146.827C198.031 148.044 197.044 149.031 195.827 149.031H178.203C176.986 149.031 176 148.044 176 146.827V129.203C176 127.986 176.986 127 178.203 127ZM178.203 129.203V141.965L182.609 137.558L186.465 141.414L193.623 134.255L195.827 136.459V129.203H178.203ZM178.203 146.827V145.08L182.609 140.674L188.763 146.827H178.203ZM195.827 146.827H191.878L188.022 142.972L193.623 137.371L195.827 139.575V146.827ZM189.218 133.607C189.218 131.782 187.739 130.303 185.914 130.303C184.089 130.303 182.609 131.782 182.609 133.607C182.609 135.433 184.089 136.912 185.914 136.912C187.739 136.912 189.218 135.433 189.218 133.607ZM184.813 133.608C184.813 132.999 185.306 132.506 185.914 132.506C186.522 132.506 187.016 132.999 187.016 133.608C187.016 134.216 186.522 134.709 185.914 134.709C185.306 134.709 184.813 134.216 184.813 133.608Z"
          fill="#419498"
        />
        <path
          d="M417.601 121.06C423.663 153.58 422.994 184.145 417.033 207.265C411.061 230.428 399.836 245.929 384.938 248.706C370.041 251.483 353.985 241.067 340.069 221.611C326.178 202.192 314.543 173.92 308.481 141.4C302.419 108.879 303.088 78.3141 309.049 55.1946C315.021 32.0316 326.246 16.5301 341.143 13.7531C356.041 10.9762 372.097 21.3928 386.013 40.8485C399.903 60.2678 411.539 88.539 417.601 121.06Z"
          fill="#DAEEEF"
          stroke="#419498"
        />
        <path
          d="M-43.5193 121.06C-49.5812 153.58 -48.9122 184.145 -42.9511 207.265C-36.9788 230.428 -25.7543 245.929 -10.8566 248.706C4.04113 251.483 20.0967 241.067 34.0131 221.611C47.9034 202.192 59.5394 173.92 65.6012 141.4C71.663 108.879 70.9941 78.3141 65.0329 55.1946C59.0606 32.0316 47.8362 16.5301 32.9385 13.7531C18.0407 10.9762 1.9852 21.3928 -11.9312 40.8485C-25.8216 60.2678 -37.4575 88.539 -43.5193 121.06Z"
          fill="#DAEEEF"
          stroke="#419498"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M343.203 120H360.827C362.044 120 363.031 120.986 363.031 122.203V139.827C363.031 141.044 362.044 142.031 360.827 142.031H343.203C341.986 142.031 341 141.044 341 139.827V122.203C341 120.986 341.986 120 343.203 120ZM343.203 122.203V134.965L347.609 130.558L351.465 134.414L358.623 127.255L360.827 129.459V122.203H343.203ZM343.203 139.827V138.08L347.609 133.674L353.763 139.827H343.203ZM360.827 139.827H356.878L353.022 135.972L358.623 130.371L360.827 132.575V139.827ZM354.218 126.607C354.218 124.782 352.739 123.303 350.914 123.303C349.089 123.303 347.609 124.782 347.609 126.607C347.609 128.433 349.089 129.912 350.914 129.912C352.739 129.912 354.218 128.433 354.218 126.607ZM349.813 126.608C349.813 125.999 350.306 125.506 350.914 125.506C351.522 125.506 352.016 125.999 352.016 126.608C352.016 127.216 351.522 127.709 350.914 127.709C350.306 127.709 349.813 127.216 349.813 126.608Z"
          fill="#419498"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30.8788 120H13.2544C12.0377 120 11.0513 120.986 11.0513 122.203V139.827C11.0513 141.044 12.0377 142.031 13.2544 142.031H30.8788C32.0955 142.031 33.0819 141.044 33.0819 139.827V122.203C33.0819 120.986 32.0955 120 30.8788 120ZM30.8791 122.203V134.965L26.4727 130.558L22.6173 134.414L15.4588 127.255L13.2547 129.459V122.203H30.8791ZM30.8791 139.827V138.08L26.4727 133.674L20.3193 139.827H30.8791ZM13.2547 139.827H17.2037L21.0595 135.972L15.4588 130.371L13.2547 132.575V139.827ZM19.8637 126.607C19.8637 124.782 21.3432 123.303 23.1683 123.303C24.9934 123.303 26.4729 124.782 26.4729 126.607C26.4729 128.433 24.9934 129.912 23.1683 129.912C21.3432 129.912 19.8637 128.433 19.8637 126.607ZM24.2693 126.608C24.2693 125.999 23.7762 125.506 23.1678 125.506C22.5595 125.506 22.0663 125.999 22.0663 126.608C22.0663 127.216 22.5595 127.709 23.1678 127.709C23.7762 127.709 24.2693 127.216 24.2693 126.608Z"
          fill="#419498"
        />
      </svg>
      <div
        class="absolute left-0 right-0 bottom-4 flex items-center justify-center"
      >
        <button
          class="bg-red p-1 px-3 font-bold text-textgrey rounded-full text-base pointer-events-none select-none"
        >
          Tap &amp; hold
        </button>
      </div>
    </div> -->

    <img
      src="@/assets/intro-main.gif"
      class="max-w-[400px] w-full height-auto mx-auto block"
    />

    <div class="p-6 space-y-4 max-w-[550px] mx-auto relative z-10 text-center">
      <p class="text-textgrey text-base">
        The app that helps you save for your first home, Nude, is now officially
        available on Android and iOS!
      </p>

      <p class="text-textgrey text-base">
        We’ve got Lifetime ISAs, a market-leading interest rate and money-saving
        features to help you buy your first home sooner 🏠
      </p>

      <p class="text-textgrey text-base">
        To celebrate our big Android debut, we’re giving away 💰£10,000💰 to
        first-time buyers!
      </p>
    </div>
    <div class="pt-2 p-6">
      <router-link
        to="/enter"
        class="p-3 block rounded-full bg-red font-bold text-white w-full  text-base text-center max-w-[225px] mx-auto "
      >
        Tap To Enter
      </router-link>
    </div>
    <!-- <img src="@/assets/bits/2.png" class="absolute lg:hidden bit-3" />
    <img
      src="@/assets/bits/2.png"
      class="absolute lg:block hidden bit-desktop-2"
    /> -->
  </div>
</template>

<script>
import Vue from "vue";
import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);
export default {
  name: "Intro",
  mounted() {
    this.$confetti.update({
      particles: [
        {
          type: "heart",
        },
        {
          type: "circle",
        },
      ],
      defaultColors: ["red", "pink", "#ba0000"],
    });
  },
  methods: {
    start(event) {
      event.preventDefault();
      this.$confetti.start();
    },
    stop() {
      console.log("Stop?");
      this.$confetti.stop();
    },
  },
};
</script>

<style scoped>
.bit-1 {
  right: -150px;
  top: -184px;
  width: 500px;
}
.bit-desktop-1 {
  right: -150px;
  top: -184px;
  width: 500px;
}
.bit-2 {
  right: -19%;
  top: 13%;
  transform: rotate(259deg);
  width: 150px;
}

.bit-3 {
  left: -72px;
  margin: -35px 0 0 0;
  transform: scaleX(-1) rotate(176deg);
  width: 189px;
}

.bit-desktop-2 {
  transform: rotate(310deg);
  width: 300px;
  left: -93px;
  margin-top: -78px;
}

.bit-desktop-3 {
  right: 120px;
  top: 300px;
  transform: rotate(291deg);
  width: 189px;
}

.bit-desktop-5 {
  left: 100px;
  margin: -35px 0 0 0;
  width: 300px;
}
</style>
